import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { api_url } from "../../utils/data";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

import NoData from "../../components/NoData";
import { useAuthenticate } from "../../context/AuthContext";
import Title from "../../components/Title";
import { AiOutlineDelete } from "react-icons/ai";
import NewCategoryModal from "./NewCategoryModal";
import UpdateCategory from "./UpdateCategory";

const Categories = () => {
  const { authToken, categoryList, subscriptionDetails } = useAuthenticate();
  const [newCategoryModal, setNewCategoryModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [updateCategoryModal, setUpdateCategoryModal] = useState(false)
  

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = categoryList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(categoryList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % categoryList.length;
    setItemOffset(newOffset);
  };

  

  const deleteCategory = (category_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        await axios
          .delete(
            `${api_url}/inventory/product-categories/${category_id}/`,
            config
          )
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Category has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

  return (
    <main id="content" role="main" >
      <div className="content container-fluid bg-white">
      <Title title="Categories" subtitle="Manage your categories" />
        {
          newCategoryModal && <NewCategoryModal setModal={setNewCategoryModal} />
        }
        {
          updateCategoryModal && <UpdateCategory category={selectedCategory} setModal={setUpdateCategoryModal} />
        }
        <div className="flex justify-end mb-2">
          <button className="px-4 py-2 rounded text-white bg-[#1572E8] hover:bg-blue-500" onClick={()=>setNewCategoryModal(true)}>Add Category</button>
        </div>

        <table className="min-w-full text-left text-sm font-light text-surface ">
          <thead className=" w-screen  font-medium">
            <tr className="border border-slate-200 text-black">
              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                SRL
              </th>

              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                Category
              </th>

              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                Category Description
              </th>

              

              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((category, index) => (
                    <tr
                      key={index}
                      className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                    >
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                        {index+1}
                      </td>
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                        {category?.name}
                      </td>
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                        {category?.description}
                      </td>
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                      <div className="btn--container">
                            <Link
                              className="action-btn"
                              onClick={()=>{
                                setSelectedCategory(category)
                                setUpdateCategoryModal(true)
                              }}
                            >
                              <MdEdit />
                            </Link>
                            <Link
                              className="action-btn btn--danger btn-outline-danger"
                              to="#"
                            >
                              <AiOutlineDelete
                                onClick={() => deleteCategory(category.id)}
                              />
                            </Link>
                          </div>
                      </td>

                      
                    </tr>
                  ))}
          </tbody>
        </table>
        {categoryList.length <= 0 && <NoData />}
        <div className="card-footer border-0">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <nav>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="›"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="‹"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName="page-item active"
                    pageLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                  />
                </nav>
              </div>
            </div>
        
      </div>
    </main>
  );
};

export default Categories;
