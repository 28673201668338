import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { api_url } from "../../utils/data";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";

import NoData from "../../components/NoData";
import { useAuthenticate } from "../../context/AuthContext";
import Title from "../../components/Title";
import { AiOutlineDelete } from "react-icons/ai";
import NewUnit from "./NewUnit";
import { RotatingLines } from "react-loader-spinner";
import UpdateUnit from "./UpdateUnit";

const ProductUnits = () => {
  const { authToken } = useAuthenticate();
  const [newUnitModal, setNewUnitModal] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [updateUnitModal, setUpdateUnitModal] = useState(false)
  const [unitList, setUnitList] = useState([])
  const [gettingUnits, setGettingUnits] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(""); // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0)
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);

  const filterUnitList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);
  };

  const deleteUnit = (unit_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        await axios
          .delete(
            `${api_url}/inventory/product-unit/${unit_id}/`,
            config
          )
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Unit has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3; // Number of pages to show around the current page
    const totalPagesToShow = 5; // Total pages to show at start and end

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    // Always show the first page
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Show the numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Always show the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number) => (
      <button
        key={number}
        onClick={() => number !== "..." && handlePageClick(number)}
        className={
          number === currentPage
            ? "bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10"
            : "border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10"
        }
      >
        {number}
      </button>
    ));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getUnitList = async (page, searchQuery) => {
    setGettingUnits(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(
        `${api_url}/inventory/product-units/?page=${page}&search=${searchQuery}`,
        config
      )
      .then((response) => {
        setUnitList(response.data?.results);
        
        setNextPage(response.data?.next);
        setPrevPage(response.data?.previous);
        setTotalPages(Math.ceil(response.data?.count / 20));
        setTotalUnits(response.data?.count);
        setGettingUnits(false);
      })
      .catch((error) => {
        setGettingUnits(false);
        toast.error("There was an error getting the product unit list");
      });
  };

  useEffect(() => {
    getUnitList(currentPage, search);
  }, [currentPage, search]);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid bg-white">
      <Title title="Units" subtitle="Manage your units" />
        {
          newUnitModal && <NewUnit setModal={setNewUnitModal} />
        }
        {
          updateUnitModal && <UpdateUnit product_unit={selectedUnit} setModal={setUpdateUnitModal} />
        }
        <div className="flex justify-end mb-2">
          <button className="px-4 py-2 rounded text-white bg-[#1572E8] hover:bg-blue-500" onClick={()=>setNewUnitModal(true)}>Add Unit</button>
        </div>

        <table className="min-w-full text-left text-sm font-light text-surface ">
          <thead className=" w-screen  font-medium">
            <tr className="border border-slate-200 text-black">
              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                SRL
              </th>

              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                Name
              </th>

              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                Short Name
              </th>

              

              <th scope="col" className="bg-[#428BCA] border-r border-white text-white px-3 py-2 font-[700] text-[15px]">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {unitList?.map((unit, index) => (
                    <tr
                      key={index}
                      className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                    >
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                        {index+1}
                      </td>
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                        {unit?.name}
                      </td>
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                        {unit?.short_name}
                      </td>
                      <td className="py-2 px-3 font-[400] border-r border-slate-300 mt-2">
                      <div className="btn--container">
                            <Link
                              className="action-btn"
                              onClick={()=>{
                                setSelectedUnit(unit)
                                setUpdateUnitModal(true)
                              }}
                            >
                              <MdEdit />
                            </Link>
                            <Link
                              className="action-btn btn--danger btn-outline-danger"
                              to="#"
                            >
                              <AiOutlineDelete
                                onClick={() => deleteUnit(unit.id)}
                              />
                            </Link>
                          </div>
                      </td>

                      
                    </tr>
                  ))}
          </tbody>
        </table>
        {
          gettingUnits && (
            <RotatingLines type="Grid" color="blue" height={30} width={30} />
          )
        }
        {unitList.length <= 0 && !gettingUnits && <NoData />}
        <div className="card-footer border-0">
              <div className="d-flex items-center justify-content-between">
                <p>
                  Page {currentPage} of {totalPages}
                </p>
                <div className="d-flex gap-3">
                  <button
                    className={`border border-primary py-2 px-4 ${
                      !prevPage
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                    }`}
                    onClick={handlePreviousPage}
                    disabled={!prevPage}
                  >
                    Previous
                  </button>
                  {renderPageNumbers()}
                  <button
                    className={`border border-primary py-2 px-4 ${
                      !nextPage
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                    }`}
                    onClick={handleNextPage}
                    disabled={!nextPage}
                  >
                    Next
                  </button>
                </div>
                
              </div>
            </div>
        
      </div>
    </main>
  );
};

export default ProductUnits;
