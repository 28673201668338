import React, { useState } from 'react'
import MedicineStock from './MedicineStock'
import Categories from './Categories'

const InventoryManagement = () => {
    const [activeTab, setActiveTab] = useState(1)
  return (
    <main id="content" role="main" className='main main-page pointer-event'>
        <div className="content container-fluid">
            <div className="page-header">
                <h1 className="mb-0 page-header-title">
                    <span>Inventory Management</span>
                </h1>
            </div>
            <div className="flex border-b border-gray-200">
                <div onClick={()=>setActiveTab(1)} className={`${activeTab === 1 ? 'text-blue-500 border-b-2 border-blue-500' : ''} px-4 py-2 cursor-pointer `}>
                    Inventory
                </div>
                <div onClick={()=>setActiveTab(2)} className={`${activeTab === 2 ? 'text-blue-500 border-b-2 border-blue-500' : ''} px-4 py-2 cursor-pointer `}>
                    Inventory Categories
                </div>
            </div>
            {activeTab === 1 && <MedicineStock />}
            {activeTab === 2 && <Categories />}
        </div>
    </main>
  )
}

export default InventoryManagement