import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";

const EmployeeDetails = () => {
  const { employee_id } = useParams();
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [employeeUpdateDetails, setEmployeeUpdateDetails] = useState(null);
  const [updatingEmployee, setUpdatingEmployee] = useState(false);
  const [gettingEmployeeDetails, setGettingEmployeeDetails] = useState(true);

  const handleFormChange = (e) => {
    setEmployeeUpdateDetails({
      ...employeeUpdateDetails,
      [e.target.name]: e.target.value,
    });
  };

  const getEmployeeDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/staff-management/employees/${employee_id}/`, config)
      .then((response) => {
        setEmployeeDetails(response.data);
        setGettingEmployeeDetails(false);
      })
      .catch((error) => {
        setGettingEmployeeDetails(false)
        toast.error("An unexpected error occured.");
      });
  };

  const updateEmployee = async (e) => {
    e.preventDefault();
    setUpdatingEmployee(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = new FormData();

    Object.keys(employeeUpdateDetails).forEach((key) => {
      formData.append(key, employeeUpdateDetails[key]);
    });

    await axios
      .put(`${api_url}/staff-management/employees/${employee_id}/`, formData, config)
      .then((response) => {
        setUpdatingEmployee(false);
        Swal.fire({
          icon: "success",
          title: "Employee updated",
          text: "Employee's details has been updated successfully",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setUpdatingEmployee(false);
        if(error?.response?.status === 500){
          return toast.error("Internal server error");
        }
        if(error?.response?.status === 400){
          for(const property in error.response.data){
            if(property !== "0"){
              toast.error(`${property} error: ${error.response.data[property]}`)
            }else{
              toast.error(`${error.response.data[property]}`)
            }
          }
        }else{
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    
    getEmployeeDetails();
  }, []);
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      {
        gettingEmployeeDetails ? (<Loading />) : (
        <div className="content container-fluid">
          <div className="page-header">
            <h1 className="page-header-title">
              <span className="page-header-icon">
                <img
                  src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                  className="w--24"
                  alt="mail"
                />
              </span>
              <span> Update Employee Details </span>
            </h1>
          </div>
          <form onSubmit={(e) => updateEmployee(e)} style={{ textAlign: "left" }}>
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-user" />
                  </span>
                  <span>General Information</span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">First Name</label>
                        <span className="text-danger">*</span>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          id="name"
                          placeholder="Ex : John Doe"
                          onChange={(e) => handleFormChange(e)}
                          required
                          value={
                            employeeUpdateDetails?.first_name
                              ? employeeUpdateDetails?.first_name
                              : employeeDetails?.user?.first_name
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Last Name</label>
                        <span className="text-danger">*</span>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          id="name"
                          placeholder="Ex : John Doe"
                          onChange={(e) => handleFormChange(e)}
                          required
                          value={
                            employeeUpdateDetails?.last_name
                              ? employeeUpdateDetails?.last_name
                              : employeeDetails?.user?.last_name
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Phone</label>
                        <span className="text-danger">*</span>
                        <input
                          type="text"
                          name="phone_number"
                          defaultValue=""
                          className="form-control"
                          id="phone"
                          placeholder="Ex : +233********"
                          onChange={(e) => handleFormChange(e)}
                          required
                          value={
                            employeeUpdateDetails?.phone_number
                              ? employeeUpdateDetails?.phone_number
                              : employeeDetails?.user?.phone_number
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Role</label>
                        <span className="text-danger">*</span>
                        <select
                          className="form-control"
                          name="position"
                          style={{ maxWidth: "100%" }}
                          onChange={(e) => handleFormChange(e)}
                        >
                          <option value="" disabled>
                            ---Select---
                          </option>
                          <option
                            selected={employeeDetails?.position === "Manager"}
                            value="Manager"
                          >
                            Manager
                          </option>
                          <option
                            selected={employeeDetails?.position === "Assistant Manager"}
                            value="Assistant Manager"
                          >
                            Assistant Manager
                          </option>
                          <option selected={employeeDetails?.position === "Shop Attendant"} value="Shop Attendant">Shop Attendant</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label d-none d-md-block">
                        {" "}
                        &nbsp;{" "}
                      </label>
                      <center className="mb-4">
                        {employeeUpdateDetails?.profile_picture ? (
                          <img
                            className="initial-24"
                            id="viewer"
                            src={URL.createObjectURL(
                              employeeUpdateDetails?.profile_picture
                            )}
                            alt="Employee thumbnail"
                          />
                        ) : (
                          <img
                            className="initial-24"
                            id="viewer"
                            src={
                              employeeDetails?.user?.profile_picture
                                ? `${employeeDetails?.user?.profile_picture}`
                                : "https://grofresh-admin.6amtech.com/public/assets/admin/img/upload-vertical.png"
                            }
                            alt="Employee thumbnail"
                          />
                        )}
                      </center>
                      <div className="form-group mb-0">
                        <label className="form-label d-block">
                          Employee Image
                        </label>
                        <div>
                          <input
                            type="file"
                            name="profile_picture"
                            // className="custom-file-input h--45px"
                            accept=".jpg, .png, .jpeg"
                            onChange={(e) => {
                              setEmployeeUpdateDetails({
                                ...employeeUpdateDetails,
                                [e.target.name]: e.target.files[0],
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-user" />
                  </span>
                  <span>Account Information</span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-4 col-sm-6">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      name="email"
                      defaultValue=""
                      className="form-control"
                      id="email"
                      placeholder="Ex : ex@gmail.com"
                      required
                      onChange={(e) => handleFormChange(e)}
                      value={
                        employeeUpdateDetails?.email
                          ? employeeUpdateDetails?.email
                          : employeeDetails?.user?.email
                      }
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label className="form-label">Username</label>
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      id="username"
                      placeholder="Ex : 8+ Characters"
                      disabled
                      value={employeeDetails?.user?.username}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label className="form-label">Password</label>
                    <input
                      type="text"
                      name="password"
                      className="form-control"
                      id="password"
                      placeholder="*****"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn--container justify-content-end mt-3">
              {!updatingEmployee ? (
                <>
                  <button type="submit" className="btn btn--primary">
                    Update
                  </button>
                </>
              ) : (
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
            </div>
          </form>
        </div>
        )
      }
    </main>
  );
};

export default EmployeeDetails;
