import axios from "axios";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";


const NewEmployee = () => {
  const {authToken, subscriptionDetails} = useAuthenticate()
  const [employeeData, setEmployeeData] = useState({})
  const [creatingEmployee, setCreatingEmployee] = useState(false)
  const [file, setFile] = useState(null)

  const handleFormChange = (e) => {
    setEmployeeData({
      ...employeeData,
      [e.target.name]:e.target.value
    })
  }

  const createEmployee = async (e) => {
    e.preventDefault()
    setCreatingEmployee(true)
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = new FormData();

    Object.keys(employeeData).forEach(key => {
      formData.append(key, employeeData[key]);
    })

    try {
      await axios.post(`${api_url}/staff-management/employees/`,formData, config).then((response)=>{
        if(response.status === 201){
          Swal.fire({
            title: "Created!",
            text: "Employee has been created.",
            icon: "success",
          }).then(()=>{
            window.location.href = "/staff-management/employees"
          })
          setCreatingEmployee(false)
        }
      })
    } catch (error) {
      
      toast.error("Something went wrong")
      setCreatingEmployee(false);
    }
  }

  useEffect(()=>{
    
  },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--24"
                alt="mail"
              />
            </span>
            <span> Add New Employee </span>
          </h1>
        </div>
        <form
          onSubmit={(e)=>createEmployee(e)}
          style={{ textAlign: "left" }}
        >
          
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>General Information</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label className="form-label">First Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        id="name"
                        placeholder="Ex : John "
                        onChange={(e)=>handleFormChange(e)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Last Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        id="name"
                        placeholder="Ex : Doe "
                        onChange={(e)=>handleFormChange(e)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Phone</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="phone_number"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="Ex : +233********"
                        onChange={(e)=>handleFormChange(e)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Position</label>
                      <span className="text-danger">*</span>
                      <select
                        className="form-control"
                        name="position"
                        style={{ maxWidth: "100%" }}
                        onChange={(e)=>handleFormChange(e)}
                      >
                        <option value="" selected disabled="">
                          ---Select---
                        </option>
                        <option value="Manager">Manager</option>
                        <option value="Assistant Manager">Assistant Manager</option>
                        <option value="Shop Attendant">Shop Attendant</option>
                      </select>
                    </div>
                    
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label d-none d-md-block">
                      {" "}
                      &nbsp;{" "}
                    </label>
                    <center className="mb-4">
                      <img
                        className="initial-24"
                        id="viewer"
                        src={file ? URL.createObjectURL(file):"https://grofresh-admin.6amtech.com/public/assets/admin/img/upload-vertical.png"}
                        alt="Employee thumbnail"
                      />
                    </center>
                    <div className="form-group mb-0">
                      <label className="form-label d-block">
                        Employee Image
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <div>
                        <input
                          type="file"
                          name="profile_picture"
                          // className="custom-file-input h--45px"
                          accept=".jpg, .png, .jpeg"
                          // required
                          onChange={(e)=>{setFile(e.target.files[0]);setEmployeeData({
                            ...employeeData, [e.target.name]:e.target.files[0]
                          })}}
                        />
                        
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>Account Information</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-4 col-sm-6">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    defaultValue=""
                    className="form-control"
                    id="email"
                    placeholder="Ex : ex@gmail.com"
                    // required
                    onChange={(e)=>handleFormChange(e)}
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    id="username"
                    placeholder="Ex : 8+ Characters"
                    onChange={(e)=>handleFormChange(e)}
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <label className="form-label">Password</label>
                  <input
                    type="text"
                    name="password"
                    className="form-control"
                    id="password"
                    placeholder="Ex : 8+ Characters"
                    required
                    onChange={(e)=>handleFormChange(e)}
                  />
                </div>
                
              </div>
            </div>
          </div>
          <div className="btn--container justify-content-end mt-3">
            {
              !creatingEmployee ? (
                <>
                  <button type="reset" className="btn btn--reset">
                    Reset
                  </button>
                  <button type="submit" className="btn btn--primary">
                    Submit
                  </button>
                </>
              ):(
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )
            }
          </div>
        </form>
      </div>
      
      
    </main>
  );
};

export default NewEmployee;
