import React, { useState } from 'react'
import CustomerList from './CustomerList'
import Suppliers from '../SupplierManagement/Suppliers'

const Contacts = () => {
    const [activeTab, setActiveTab] = useState(1)
  return (
    <main id="content" role="main" className='main main-page pointer-event'>
        <div className="content container-fluid">
            <div className="page-header">
                <h1 className="mb-0 page-header-title">
                    <span>Contacts</span>
                </h1>
            </div>
            <div className="flex border-b border-gray-200">
                <div onClick={()=>setActiveTab(1)} className={`${activeTab === 1 ? 'text-blue-500 border-b-2 border-blue-500' : ''} px-4 py-2 cursor-pointer `}>
                    Customers
                </div>
                <div onClick={()=>setActiveTab(2)} className={`${activeTab === 2 ? 'text-blue-500 border-b-2 border-blue-500' : ''} px-4 py-2 cursor-pointer `}>
                    Suppliers
                </div>
            </div>
            {activeTab === 1 && <CustomerList />}
            {activeTab === 2 && <Suppliers />}
        </div>
    </main>
  )
}

export default Contacts