import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import ReactPaginate from "react-paginate";
import NoData from "../../components/NoData";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const LowStockProducts = () => {
  const { authToken, pharmacyDetails, categoryList, user } = useAuthenticate();
  const productListRef = useRef();
  
  const [allProducts, setAllProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(""); // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [gettingProductList, setGettingProductList] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [productType, setProductType] = useState("")

  const today = new Date().toLocaleDateString();

  const filterProductList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);
  };

  const getAllProductList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/inventory/all-products/`, config)
      .then((response) => {
        setAllProducts(response.data);
      })
      .catch((error) => {
        toast.error("There was an error getting product list");
      });
  };

  const getProductList = async (page, searchQuery, category) => {
    setGettingProductList(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(
        `${api_url}/inventory/products/?page=${page}&search=${searchQuery}&product_type=Low Stock&category=${category}`,
        config
      )
      .then((response) => {
        
        setProductList(response.data?.results);
        setNextPage(response.data?.next);
        setPrevPage(response.data?.previous);
        setTotalPages(Math.ceil(response.data?.count / 20));
        setTotalProducts(response.data?.count);
        setGettingProductList(false);
      })
      .catch((error) => {
        setGettingProductList(false);
        toast.error("There was an error getting your drug list");
      });
  };

  const deleteProduct = (product_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        axios
          .delete(`${api_url}/inventory/products/${product_id}/`, config)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Product has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3; // Number of pages to show around the current page
    const totalPagesToShow = 5; // Total pages to show at start and end

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    // Always show the first page
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Show the numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Always show the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number) => (
      <button
        key={number}
        onClick={() => number !== "..." && handlePageClick(number)}
        className={
          number === currentPage
            ? "bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10"
            : "border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10"
        }
      >
        {number}
      </button>
    ));
  };

  useEffect(() => {
    getAllProductList();
  }, []);

  useEffect(() => {
    getProductList(currentPage, search, selectedCategory);
  }, [currentPage, search, selectedCategory]);

  return (
    <main id="content" className="main main-page pointer-event" >
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">Low stock products</span>
          </h1>
        </div>
       
        <div className="col-lg-12 mb-5">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-dollar" />
                  </span>
                  <span> Filters </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="p-2">
                  <div className="row g-3">
                    

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Category
                          
                        </label>
                        <select
                          name="product_type"
                          className="form-control js-select2-custom"
                          onChange={(e)=>setSelectedCategory(e.target.value)}
                        >
                          <option value="">---Select---</option>
                          {categoryList.map((category, index) => (
                          <option value={category.id} key={index}>
                            {category.name}
                          </option>
                        ))}
                        </select>
                      </div>
                    </div>


                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="card">
            <div className="card-body p-5px">
              <div className="order-top">
                <div className="card--header">
                  <form>
                    <div className="input-group">
                      <input
                        id="datatableSearch_"
                        type="search"
                        name="search"
                        className="form-control"
                        placeholder="Search by Product Name"
                        aria-label="Search"
                        required
                        
                        onChange={(e) => filterProductList(e.target.value)}
                      />
                      
                    </div>
                  </form>

                
                </div>
              </div>
              <div className="table-responsive datatable-custom">
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th className="table-column-pl-0">SKU</th>
                      <th>Name</th>
                      <th>Current Stock</th>
                      <th>Cost Price</th>
                      <th>Selling Price</th>
                      <th>Markup rate(%)</th>
                      {
                        user?.account_type !== "Shop Attendant" && (

                          <th>
                            <div className="text-center">Action</div>
                          </th>
                        )
                      }
                    </tr>
                  </thead>
                  <tbody id="set-rows">
                    {productList?.map((product, index) => (
                      <tr className="status-delivered class-all">
                        <td className="">{index + 1}</td>
                        <td className="table-column-pl-0">
                          <Link href="#">{product?.sku}</Link>
                        </td>
                        <td>{product?.name}</td>
                        <td>
                          <span>{product?.quantity}</span>
                        </td>
                        <td>{product?.manufacturer_price}</td>
                        <td>{product?.final_price}</td>
                        <td>{product?.markup_rate}</td>
                        {
                          user?.account_type !== "Shop Attendant" && (
                            <td>
                              <div className="btn--container justify-content-center">
                                <Link
                                  to={`/product/${product.id}/details`}
                                  className="action-btn"
                                  title="Edit"
                                >
                                  <MdEdit />
                                </Link>
                                <Link className="action-btn btn--danger btn-outline-danger">
                                  <MdDeleteOutline
                                    onClick={() => deleteProduct(product.id)}
                                  />
                                </Link>
                              </div>
                            </td>
                          )
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
                {
                  gettingProductList && (
                    <div className="flex items-center justify-center overflow-hidden">

                      <RotatingLines type="Grid" color="blue" height={30} width={30} />
                    </div>
                  )
                }

                {productList?.length <= 0 && !gettingProductList && <NoData />}
              </div>
              {/* TABLE TO PRINT */}
              <div style={{ display: "none" }}>
                <div ref={productListRef} className="py-5 px-2">
                  <div className="text-center border-b border-slate-200 pb-4 mb-4">
                    <h1 className="text-[35px] m-0">
                      {pharmacyDetails?.pharmacy_name}
                    </h1>
                    <p className="m-0">{pharmacyDetails?.pharmacy_location}</p>
                    <p className="m-0">
                      {pharmacyDetails?.pharmacy_email}{" "}
                      {pharmacyDetails?.pharmacy_phone}
                    </p>
                  </div>

                  <p>Date Printed: {new Date().toLocaleDateString()}</p>
                  <div  className="mt-7">
                    <h3 className="text-center pb-3">Product List</h3>
                    <table
                      className="table table-hover table-bordered table-thead-bordered table-nowrap table-align-middle card-table"
                      style={{ width: "100%" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="">SL</th>
                          <th>SKU</th>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Cost Price</th>
                          <th>Selling Price</th>
                          <th>Markup rate</th>
                        </tr>
                      </thead>
                      <tbody id="set-rows">
                        {allProducts?.map((product, index) => (
                          <tr className="status-delivered class-all">
                            <td className="">{index + 1}</td>
                            <td>{product.sku}</td>
                            <td>{product.name}</td>

                            <td>
                              <span>{product.quantity}</span>
                            </td>
                            <td>
                              <span>{product.manufacturer_price}</span>
                            </td>
                            <td>
                              <div>{product.final_price}</div>
                            </td>
                            <td>
                              <div>{product.markup_rate}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer border-0">
              <div className="d-flex items-center justify-content-between">
                <p>
                  Page {currentPage} of {totalPages}
                </p>
                <div className="d-flex gap-3">
                  <button
                    className={`border border-primary py-2 px-4 ${
                      !prevPage
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                    }`}
                    onClick={handlePreviousPage}
                    disabled={!prevPage}
                  >
                    Previous
                  </button>
                  {renderPageNumbers()}
                  <button
                    className={`border border-primary py-2 px-4 ${
                      !nextPage
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                    }`}
                    onClick={handleNextPage}
                    disabled={!nextPage}
                  >
                    Next
                  </button>
                </div>
                {/* <nav>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="›"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="‹"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      activeClassName="page-item active"
                      pageLinkClassName="page-link"
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                    />
                    
                  </nav> */}
              </div>
            </div>
          </div>
        
      </div>
    </main>
  );
};

export default LowStockProducts;
