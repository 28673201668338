import React, { useState } from 'react'
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import axios from "axios";


const NewUnit = ({setModal}) => {
    const { authToken } = useAuthenticate();
    const [data, setData] = useState({});
  const [addingUnit, setAddingUnit] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setAddingUnit(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
    
        const formData = JSON.stringify(data);
    
        try {
          await axios
            .post(`${api_url}/inventory/product-units/`, formData, config)
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title: "Created!",
                  text: "Unit has been created.",
                  icon: "success",
                }).then(() => {
                  setAddingUnit(false);
                  window.location.reload();
                });
              }
            });
        } catch (error) {
          setAddingUnit(false);
          if (error?.response?.status === 500) {
            return toast.error("Internal server error");
          }
          if (error?.response?.status === 400) {
            for (const property in error.response.data) {
              if (property !== "0") {
                toast.error(`${property} error: ${error.response.data[property]}`);
              } else {
                toast.error(`${error.response.data[property]}`);
              }
            }
          } else {
            toast.error("Something unexpected happened");
          }
        }
      };
    
      const handleFormChange = (e) => {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
      };
  return (
    <div className="modal fade" id="add-customer" onClick={()=>setModal(false)}>
      <div className="modal-dialog" style={{minWidth:"430px"}} onClick={(e)=>e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">New Unit</h5>
            
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setModal(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body" style={{maxHeight:"60vh", overflow:"scroll"}}>
            <form onSubmit={(e)=>handleSubmit(e)}>
                <div className='flex flex-col'>
                    <label htmlFor="" className='text-black font-[600]'>Unit Name*</label>
                    <input type="text" className="border border-black p-2 rounded" required name='name' onChange={(e)=>handleFormChange(e)} />
                </div>
                <div className='flex flex-col my-4'>
                    <label htmlFor="" className='text-black font-[600]'>Unit Short Name</label>
                    <input onChange={(e)=>handleFormChange(e)} name="short_name" id="" className="border border-black p-2 rounded" />
                    
                </div>
                <div className="flex justify-end">
                    {
                        addingUnit ? (
                            <RotatingLines type="Grid" color="blue" height={30} width={30} />
                        ):(
                            <button className='px-4 py-2 rounded text-white bg-blue-500 hover:bg-blue-600'>Save</button>
                        )
                    }
                </div>
            </form>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewUnit