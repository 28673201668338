import React from 'react'

const Notification = ({notificationList}) => {
  return (
    <div className='absolute top-14 py-2 right-0 bg-white shadow-md min-w-96'>
        {
            notificationList?.map((notification, index) => (
                <div key={index} className='px-2 border-b border-slate-500 flex justify-between hover:cursor-pointer hover:bg-blue-50'>
                    <div className='leading-tight '>
                        <h5 className='mb-0 font-weight-600 text-[15px]'>{notification?.notification_type === "low_stock" && "Low stock"}</h5>
                        <p className='text-[13px]'>
                            {notification?.details}
                        </p>
                    </div>
                    <p className='text-[12px]'>{notification?.date}</p>
                </div>
            ))
        }

    </div>
  )
}

export default Notification