import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthenticate } from "../../../context/AuthContext";
import axios from "axios";
import { api_url } from "../../../utils/data";

const PharmacyDetails = () => {
    const {authToken} = useAuthenticate()
    const {pharmacy_id} = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [shopInfo, setShopInfo] = useState({});
  const [updateData, setUpdateData] = useState({})
  const [loadingPharmacy, setLoadingShop] = useState(true)

    const updateFormData = (e) => {
        setUpdateData({
            ...updateData, [e.target.name]:e.target.value
        })
    }

  const getShopDetails = async ()=>{
    const config = {
        "headers":{
            "Content-Type": "application/json",
            Authorization:`Bearer ${authToken}`
        }
    }

    await axios.get(`${api_url}/accounts/pharmacy/${pharmacy_id}/`, config).then((response)=>{
        setShopInfo(response.data)
        setLoadingShop(false)
    }).catch((error)=>{
        console.log(error);
        setLoadingShop(false);
    })
  }

  useEffect(()=>{
    getShopDetails();
  }, [])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span>Shop</span>
          </h1>
          <ul className="nav nav-tabs border-0 mb-3">
            <li className="nav-item">
              <a
                href="#"
                className={`nav-link ${activeTab === 1 && "active"}`}
                onClick={() => setActiveTab(1)}
              >
                Shop Details
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className={`nav-link ${activeTab === 2 && "active"}`}
                onClick={() => setActiveTab(2)}
              >
                Shop Staff
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className={`nav-link ${activeTab === 3 && "active"}`}
                onClick={() => setActiveTab(3)}
              >
                Subscription Report
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div className={`tab-pane fade ${activeTab == 1 && "show active"}`} id="business-setting">
            
            <div className="card">
              <div className="card-header">
                <h5 className="card-title d-flex align-items-center">
                  
                  <span>Shop Information</span>
                </h5>
              </div>
              <div className="card-body">
                <form
                >
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Shop Name
                        </label>
                        <input
                          type="text"
                          name="pharmacy_name"
                          className="form-control"
                          value={
                            updateData?.pharmacy_name ? updateData.pharmacy_name : shopInfo?.pharmacy_information?.pharmacy_name
                          }
                          onChange={(e)=>updateFormData(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="input-label" htmlFor="country">
                          Shop Location
                        </label>
                        <input
                          type="text"
                          name="pharmacy_location"
                          className="form-control"
                          value={
                            updateData?.pharmacy_location ? updateData.pharmacy_location : shopInfo?.pharmacy_information?.pharmacy_location
                          }
                          onChange={(e)=>updateFormData(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Shop Phone Number
                        </label>
                        <input
                          type="text"
                          name="pharmacy_phone"
                          className="form-control"
                          value={
                            updateData?.pharmacy_phone ? updateData.pharmacy_phone : shopInfo?.pharmacy_information?.pharmacy_phone
                          }
                          onChange={(e)=>updateFormData(e)}
                          required
                        />
                      </div>
                    </div>
                    
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="pharmacy_email"
                          className="form-control"
                          value={
                            updateData?.pharmacy_email ? updateData.pharmacy_email : shopInfo?.pharmacy_information?.pharmacy_email
                          }
                          onChange={(e)=>updateFormData(e)}
                        />
                      </div>
                    </div>
                    
                  </div>
                  {/* <div className="btn--container justify-content-end mt-5">
                    <button type="reset" className="btn btn--reset">
                      Reset
                    </button>
                    <button
                      type="button"
                      className="btn btn--primary call-demo"
                    >
                      Save
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>

          <div className={`tab-pane fade ${activeTab == 2 && "show active"}`} id="business-setting">
            
            <div className="card">
              <div className="card-header">
                <h5 className="card-title d-flex align-items-center">
                  
                  <span>Shop Staff</span>
                </h5>
              </div>
              <div className="card-body">
                <div className="table-responsive datatable-custom">
                    <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                        <thead className="thead-light">
                            <tr>
                                <th className="">SL</th>
                                <th className="">Staff ID</th>
                                <th>Username</th>
                                <th>Staff Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Position</th>
                            </tr>
                        </thead>
                        <tbody id="set-rows">
                            {
                                shopInfo?.pharmacy_staffs?.map((staff, index)=>(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{staff?.staff_id}</td>
                                        <td>{staff?.username}</td>
                                        <td>{staff?.full_name}</td>
                                        <td>{staff?.email}</td>
                                        <td>{staff?.phone_number}</td>
                                        <td>{staff?.position}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>

          <div className={`tab-pane fade ${activeTab == 3 && "show active"}`} id="business-setting">
            
          <div className="card mb-3">
              <div className="card-header">
                <h5 className="card-title d-flex align-items-center"> 
                  <span>Current Subscription</span>
                </h5>
              </div>
              {
                shopInfo?.current_subscription ? (
                    <div className="card-body">
                        <p>
                            <b>Subscription Package: </b> {shopInfo?.current_subscription?.package}
                        </p>
                        <p>
                            <b>Subscription Duration: </b> {shopInfo?.current_subscription?.duration}
                        </p>
                        <p>
                            <b>Registration Date: </b> {shopInfo?.current_subscription?.registration_date}
                        </p>
                        <p>
                            <b>Expiry Date: </b> {shopInfo?.current_subscription?.expiry_date}
                        </p>
                        <p>
                            <b>Subscription Cost: </b> {shopInfo?.current_subscription?.package_cost}
                        </p>
                    </div>
                ):(
                    <div className="card-body">
                        <h3>No Active Subscription</h3>
                    </div>
                )
              }
            </div>

            <div className="card">
              <div className="card-header">
                <h5 className="card-title d-flex align-items-center"> 
                  <span>Previous Subscriptions</span>
                </h5>
              </div>
              <div className="card-body">
                <div className="table-responsive datatable-custom">
                    <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                        <thead className="thead-light">
                            <tr>
                                <th className="">SL</th>
                                <th className="">Subscription Package</th>
                                <th>Date</th>
                                <th>Expiry Date</th>
                            </tr>
                        </thead>
                        <tbody id="set-rows">
                            {
                                shopInfo?.pharmacy_subscriptions?.map((subscription, index)=>(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {
                                                subscription?.package
                                            }
                                        </td>
                                        <td>
                                            {
                                                subscription?.registration_date
                                            }
                                        </td>
                                        <td>
                                            {
                                                subscription?.expiry_date
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </main>
  );
};

export default PharmacyDetails;
