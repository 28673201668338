import React from 'react'
import { Link } from 'react-router-dom'

const ExpiringDrugsModal = ({drugList, setModal}) => {
  return (
    <div className="modal fade" id="add-customer" onClick={()=>setModal(false)}>
      <div className="modal-dialog" style={{minWidth:"430px"}} onClick={(e)=>e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Expring Drugs</h5>
            
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setModal(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body" style={{maxHeight:"60vh", overflow:"scroll"}}>
          <p>You have {drugList?.length} Drugs that are expiring!!!</p>
            <ul>
                {
                    drugList?.map((drug, index)=>(

                        <li key={index}>
                            <Link to={`/product/${drug?.id}/details`}>
                                {drug?.name}
                            </Link> 
                            <p>
                                Expiry Date: {drug?.expiry_date}
                            </p>
                        </li>
                    ))
                }
                
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpiringDrugsModal