import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { api_url } from "../../utils/data";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuthenticate } from "../../context/AuthContext";


const AddDrug = () => {
  const {categoryList, authToken} = useAuthenticate()
  const [unitList, setUnitList] = useState([])
  
  const [data, setData] = useState({
    manufacturer_price: 0,
    final_price: 0,
    markup_rate: 0,
    clearance_date:null,
    clearance_discount:0
  });
  const [addingProduct, setAddingProduct] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const calculateFinalPrice = (manufacturer_price, markup_rate) => {
    if (manufacturer_price !== "") {
      let tax_amount = parseFloat(manufacturer_price) * (parseFloat(markup_rate) / 100);
      
      let final_price = parseFloat(parseFloat(manufacturer_price) + tax_amount).toFixed(2);
      
      setData({
        ...data,
        ["manufacturer_price"]: manufacturer_price,
        ["markup_rate"]: markup_rate,
        ["final_price"]: final_price,
      });
    }
  };

  const calculateMarkupRate = (final_price, manufacturer_price) => {
    
    if(manufacturer_price !== "") {
      let profit = parseFloat(final_price) - parseFloat(manufacturer_price);
      let percentage_profit = (profit/parseFloat(manufacturer_price) ) * 100;
      setData({
        ...data,
        ["manufacturer_price"]: manufacturer_price,
        ["markup_rate"]: percentage_profit.toFixed(2),
        ['final_price']: final_price
      })
    }
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setAddingProduct(true);
    console.log(data)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = JSON.stringify(data);

    

    try {
      await axios
        .post(`${api_url}/inventory/products/`, formData, config)
        .then((response) => {
          if (response.status === 201) {
            Swal.fire({
              title: "Created!",
              text: "Product has been created.",
              icon: "success",
            }).then(() => {
              setAddingProduct(false);
              window.location.reload();
            });
          }
        });
    } catch (error) {
      
      
      setAddingProduct(false);
      if(error?.response?.status === 500){
        return toast.error("Internal server error");
      }
      if(error?.response?.status === 400){
        for(const property in error.response.data){
          if(property !== "0"){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }else{
        toast.error("Something went wrong");
      }
    }
  };

  const calculateClearanceDate = (days) => {
    
    if (days === "" || parseInt(days) <= 0){
      setData({
        ...data, ['clearance_date'] : null
      })
      return;
    }
    const resultDate = new Date() // today's date
    resultDate.setDate(resultDate.getDate() + parseInt(days)); // add days to today's date
    setData({
      ...data, ['clearance_date'] : resultDate.toISOString().slice(0, 10), ['days_to_clearance'] : days
    })
    
  }

  const getUnitList = async () => {
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(
        `${api_url}/inventory/all-product-units/`,
        config
      )
      .then((response) => {
        setUnitList(response.data);
        
        
      })
      .catch((error) => {
        
        
      });
  };

  useEffect(()=>{
    getUnitList();
  },[])

 

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            
            <span> Add New Product </span>
          </h1>
        </div>
        <form
          onSubmit={(e) => submitForm(e)}
          id="product_form"
          className="row g-2"
        >
          
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-dollar" />
                  </span>
                  <span> Product Details </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="p-2">
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Product Name
                          
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="en_name"
                          className="form-control"
                          placeholder="New Product"
                          required
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          SKU
                          
                        </label>
                        <div className="flex flex-col">

                          <input
                            type="text"
                            name="sku"
                            id="en_name"
                            className="form-control"
                            
                            onChange={(e) => handleFormChange(e)}
                          />
                          <small>Leave blank to autogenerate</small>
                        </div>
                      </div>
                    </div>
                    

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Category
                        </label>
                        <select
                        name="category"
                        className="form-control js-select2-custom"
                        onChange={(e) => handleFormChange(e)}
                      >
                        <option value="">---Select---</option>
                        {categoryList.map((category, index) => (
                          <option value={category.id} key={index}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Days to clearance
                        </label>
                        <div className="flex flex-col">
                          <input
                            type="number"
                            min={0}
                            max={10000}
                            step="any"
                            name="days_to_clearance"
                            className="form-control"
                            onChange={(e)=>calculateClearanceDate(e.target.value)}
                            
                            
                          />
                          {
                            data?.clearance_date && (
                              <small>
                                This product will be moved to clearance on {data?.clearance_date}
                              </small>
                            )
                          }
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Clearance Discount (%)
                        </label>
                        <div className="flex flex-col">
                          <input
                            type="number"
                            min={0}
                            max={10000}
                            step={0.01}
                            value={data?.clearance_discount}
                            name="clearance_discount"
                            className="form-control"
                            onChange={(e)=>handleFormChange(e)}
                            
                          />
                          {
                            data?.clearance_date && (
                              <small>
                                This product will be discounted in this percentage if it is moved to clearance
                              </small>
                            )
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="form-group mt-5 mb-0">
                  <label className="input-label" htmlFor="en_description">
                    Product Description
                  </label>
                  <textarea
                    name="description"
                    className="form-control h--172px"
                    id="en_hiddenArea"
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-dollar" />
                  </span>
                  <span> Price information </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="p-2">
                  <div className="row g-3">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Product Type
                          
                        </label>
                        <select
                          name="medicine_type"
                          className="form-control js-select2-custom"
                          onChange={(e) => handleFormChange(e)}
                        >
                          <option value="">---Select---</option>
                          <option value="Local">Local</option>
                          <option value="Foreign">Foreign</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Manufacturer's price
                        </label>
                        <input
                          type="number"
                          min={0}
                          max={100000000}
                          step="any"
                          // value={data?.manufacturer_price}
                          name="manufacturer_price"
                          className="form-control"
                          placeholder="Ex : 349"
                          required
                          onChange={(e) => {
                            // handleFormChange(e);
                            calculateFinalPrice(e.target.value, data?.markup_rate);
                            calculateMarkupRate(data?.final_price,e.target.value,)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Product stock
                        </label>
                        <input
                          type="number"
                          min={0}
                          max={100000000}
                          name="quantity"
                          className="form-control"
                          placeholder="Ex : 100"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Markup <span id="tax_symbol">(%)</span>
                        </label>
                        <input
                          type="number"
                          min={0}
                          value={data?.markup_rate}
                          step="0.01"
                          max={100000}
                          name="markup_rate"
                          className="form-control"
                          placeholder="Ex : 10"
                          required
                          
                          onChange={(e) => {
                            handleFormChange(e);
                            calculateFinalPrice(
                              data?.manufacturer_price,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Final Unit price
                        </label>
                        <input
                          type="number"
                          min={0}
                          max={100000000}
                          step="any"
                          name="final_price"
                          className="form-control"
                          onChange={(e)=>calculateMarkupRate(e.target.value,data?.manufacturer_price,)}
                          required
                          value={data?.final_price}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Expiry Date
                        </label>
                        <input
                          type="date"
                          
                          
                          name="expiry_date"
                          className="form-control"
                          
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="btn--container justify-content-end">
              {!addingProduct ? (
                <>
                  
                  <button type="submit" className="btn btn--primary">
                    Submit
                  </button>
                </>
              ) : (
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default AddDrug;
