import React, { useEffect, useState } from "react";

import ReactPaginate from "react-paginate";
import AddNewCustomerModal from "./AddNewCustomerModal";
import { MdDeleteOutline } from "react-icons/md";
import SaleDrugDetailsModal from "./SaleDrugDetailsModal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import LogoSmall from "../../assets/rxsmall.jpeg";
import NoData from "../../components/NoData";

import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";

const NewSale = () => {
  const { categoryList,authToken, subscriptionDetails } = useAuthenticate();
  const [gettingMedicineList, setGettingMedicineList] = useState(true);
  const [medicineList, setMedicineList] = useState([]);
  const [drugList, setDrugList] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [addMedicineModal, setAddMedicineModal] = useState(false);
  const [subtotal, setSubtotal] = useState("0.00");
  const [totalTax, setTotalTax] = useState("0.00");
  const [totalCost, setTotalCost] = useState(0.0);
  const [cart, setCart] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("Full Payment");
  const [customer, setCustomer] = useState("Walk in customer");
  const [amountPaid, setAmountPaid] = useState("0.00");
  const [balance, setBalance] = useState("0.00");

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');  // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalMedicine, setTotalMedicine] = useState(0);
  

  const displayAddMedicineModal = (medicine) => {
    setSelectedMedicine(medicine);
    setAddMedicineModal(true);
  };

    const getMedicineList = async (page, searchQuery) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
  
      await axios.get(`${api_url}/inventory/products/?page=${page}&search=${searchQuery}`, config).then((response)=>{
        setDrugList(response.data?.results);
        setMedicineList(response.data?.results);
        setNextPage(response.data?.next);
        setPrevPage(response.data?.previous);
        setTotalPages(Math.ceil(response.data?.count / 20));
        setTotalMedicine(response.data?.count);
        setGettingMedicineList(false);
      }).catch((error) => {
        setGettingMedicineList(false);
      });
    };

    const filterDrugList = (searchValue) => {
      setSearch(searchValue);
      setCurrentPage(1);
    };

    const handleNextPage = () => {
      if (nextPage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const handlePreviousPage = () => {
      if (prevPage) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handlePageClick = (pageNumber) => {
      setCurrentPage(pageNumber);
  };
  
    const renderPageNumbers = () => {
      const pageNumbers = [];
      const totalNumbersToShow = 3; // Number of pages to show around the current page
      const totalPagesToShow = 5; // Total pages to show at start and end
  
      let startPage = Math.max(1, currentPage - totalNumbersToShow);
      let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);
  
      // Always show the first page
      if (startPage > 1) {
          pageNumbers.push(1);
          if (startPage > 2) {
              pageNumbers.push('...');
          }
      }
  
      // Show the numbers around the current page
      for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
      }
  
      // Always show the last page
      if (endPage < totalPages) {
          if (endPage < totalPages - 1) {
              pageNumbers.push('...');
          }
          pageNumbers.push(totalPages);
      }
  
      return pageNumbers.map(number => (
          <button
              key={number}
              onClick={() => number !== '...' && handlePageClick(number)}
              className={number === currentPage ? 'bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10' : 'border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10'}
          >
              {number}
          </button>
      ));
  };

    const filterByCategory = (category_id) => {
      if (category_id === "All Categories") {
        setMedicineList(drugList);
      } else {
        const filteredMedicineList = drugList.filter((medicine) => {
          return category_id === ""
            ? medicine
            : parseInt(medicine.category) === parseInt(category_id);
        });
        setMedicineList(filteredMedicineList);
      }
    };

  

  const removeCartItem = (index) => {
    const cartData = [...cart];
    const cartItemTotalCost =
      cartData[index].drug_unit_price * cartData[index].quantity;
    const subTotal = parseFloat(
      parseFloat(subtotal) - parseFloat(cartItemTotalCost)
    ).toFixed(2);
    const initialUnitTaxAmount =
      parseFloat(cartData[index]?.drug_final_price) -
      parseFloat(cartData[index]?.drug_unit_price);
    const finalTaxAmount = parseFloat(
      cartData[index]?.quantity * initialUnitTaxAmount
    ).toFixed(2);
    const newTotalTax = parseFloat(
      parseFloat(totalTax) - finalTaxAmount
    ).toFixed(2);

    const cartItemFinalTotalCost =
      cartData[index].drug_final_price * cartData[index].quantity;
    const newTotalCost = parseFloat(
      parseFloat(totalCost) - parseFloat(cartItemFinalTotalCost)
    ).toFixed(2);

    cartData.splice(index, 1);
    setCart(cartData);
    setSubtotal(subTotal);
    setTotalTax(newTotalTax);
    setTotalCost(newTotalCost);
  };

  const emptyCart = () => {
    setCart([]);
    setSubtotal("0.00");
    setTotalCost("0.00");
    setTotalTax("0.00");
    setAmountPaid("0.00");
    setBalance("0.00");
  };

  const placeOrder = async (e) => {
    e.preventDefault();
    setPlacingOrder(true);
    if (paymentStatus === "Part Payment" && customer === "Walk in customer") {
      toast.error("You cannot accept part payment for a walk in customer");
      setPlacingOrder(false);
      return false;
    }

    if (cart.length <= 0) {
      toast.error("Cart must not be empty");
      setPlacingOrder(false);
    } else {
      setPlacingOrder(true);
      let amount_paid = 0.0;
      let outstanding_balance = 0.0;
      if (paymentStatus === "Full Payment") {
        amount_paid = totalCost;
        outstanding_balance = 0.0;
      } else {
        amount_paid = amountPaid;
        // outstanding_balance = balance;
        outstanding_balance = parseFloat(
          parseFloat(totalCost) - parseFloat(amountPaid)
        ).toFixed(2);
      }
      const body = JSON.stringify({
        cart: cart,
        payment_status: paymentStatus,
        customer: customer,
        amount_paid: amount_paid,
        total_cost: totalCost,
        outstanding_balance: outstanding_balance,
        // sub_total: subtotal,
        // total_tax: totalTax
      });

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };

        try {
          await axios
            .post(`${api_url}/sales/`, body, config)
            .then((response) => {
              setPlacingOrder(false);
              if (response.status === 201) {
                Swal.fire({
                  title: "Recorded!",
                  text: "Sale has been recorded.",
                  icon: "success",
                }).then(() => {
                  emptyCart();
                  window.location.href = `/sales/${response.data?.id}/details`;
                  setPlacingOrder(false);
                });
              }
            });
        } catch (error) {
          toast.error("Something went wrong.");
          setPlacingOrder(false);
        }
    }
  };

    const getCustomersList = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      try {
        await axios
          .get(`${api_url}/accounts/pharmacy-customers/`, config)
          .then((response) => {
            if (response.status === 200) {
              setCustomersList(response.data);
            }
          });
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    };

    useEffect(()=>{
      getMedicineList(currentPage, search)
    },[currentPage, search])

    useEffect(()=>{
      
      getCustomersList();
    },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="d-flex flex-wrap">
          <div className="order--pos-left">
            <div className="card">
              <div className="card-header m-1 bg-light border-0">
                <h5 className="card-title">
                  <span> Products section </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row mb-4 g-3">
                  

                  {/* SEARCH BOX */}
                  <div className="col-sm-6">
                    <form id="search-form">
                      <div className="input-group input-group-merge input-group-flush">
                        <div className="input-group-prepend w--30 justify-content-center">
                          <div className="input-group-text">
                            <i className="tio-search" />
                          </div>
                        </div>
                        <input
                          id="datatableSearch"
                          type="search"
                          defaultValue=""
                          name="search"
                          className="form-control rounded border"
                          placeholder="Search by product name"
                          aria-label="Search here"
                            onChange={(e) => filterDrugList(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>

                {/* DRUG LIST */}
                <div id="items">
                  <div className="row g-1">
                    <div className="table-responsive datatable-custom">
                      <table
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                        style={{ width: "100%" }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="table-column-pl-0">SKU</th>
                            <th>Name</th>
                            
                            <th>Quantity Left</th>
                            <th>Unit Price</th>
                          </tr>
                        </thead>
                        
                        <tbody id="set-rows">
                          {medicineList.map((medicine, index) => (
                            <tr
                              key={index}
                              style={{cursor:"pointer"}}
                              onClick={() => displayAddMedicineModal(medicine)}
                              className="status-delivered class-all"
                            >
                              <td className="table-column-pl-0">
                                {medicine?.sku}
                              </td>
                              <td>{medicine?.name}</td>
                              
                              <td>
                                <span>{medicine?.quantity}</span>
                              </td>
                              <td>
                                <div>{medicine?.final_price}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                         
                      </table>
                    </div>
                  </div>
                  {
                    gettingMedicineList && (
                      <div style={{display:"flex", alignItems:"center", justifyContent:"center", padding:"30px"}}>
                              <RotatingLines
                                  visible={true}
                                  height="96"
                                  width="96"
                                  strokeColor="blue"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                            </div>
                    )
                  }
                  {medicineList.length <= 0 && !gettingMedicineList && <NoData />}
                </div>
                <div className="pt-4">
                <div className="d-flex gap-3">
                    <button className={`border border-primary py-2 px-4 ${!prevPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handlePreviousPage} disabled={!prevPage}>
                      Previous
                    </button>
                    {renderPageNumbers()}
                    <button className={`border border-primary py-2 px-4 ${!nextPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handleNextPage} disabled={!nextPage}>
                      Next
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="order--pos-right">
            {/* BILLING SECTION */}
            <div className="card">
              <div className="card-header bg-light border-0 m-1">
                <h5 className="card-title">
                  <span> Billing Section </span>
                </h5>
              </div>
              <div className="card-body p-0">
                <div className="px-4">
                  <div className="w-100">
                    {
                      subscriptionDetails?.subscription?.package !== "Alpha" && (
                        <div className="d-flex flex-wrap flex-row py-2 add--customer-btn">
                          <select
                            id="customer"
                            name="customer"
                            onChange={(e) => setCustomer(e.target.value)}
                            className="js-data-example-ajax form-control m-1"
                          >
                            <option selected value="Walk in customer">
                              Walk in customer
                            </option>
                            {customersList.map((customer, index) => (
                              <option value={customer.id} key={index}>
                                {customer.customer_name}
                              </option>
                            ))}
                          </select>
                          <button
                            className="btn btn--primary rounded font-regular"
                            data-toggle="modal"
                            data-target="#add-customer"
                            type="button"
                            onClick={() => setAddCustomerModal(true)}
                          >
                            Add New Customer
                          </button>
                        </div>
                      )
                    }
                  </div>

                  <div className="w-100" id="cart">
                    <div className="d-flex flex-row cart--table-scroll">
                      <div className="table-responsive">
                        <table className="table table-bordered border-left-0 border-right-0 middle-align">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Item</th>
                              <th scope="col" className="text-center">
                                Qty
                              </th>
                              <th scope="col">Price</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart.map((cartItem, index) => (
                              <tr key={index}>
                                <td>
                                  <div class="media align-items-center">
                                    
                                    <div class="media-body">
                                      <h6 class="text-hover-primary mb-0">
                                        {cartItem?.drug_name}
                                      </h6>
                                      <small></small>
                                    </div>
                                  </div>
                                </td>
                                <td class="align-items-center text-center">
                                  {cartItem?.quantity}
                                  
                                </td>
                                <td class="text-center px-0 py-1">
                                  <div class="btn text-left">
                                    GHS {cartItem?.total_cost}
                                  </div>
                                </td>
                                <td>
                                  <div class="d-flex flex-wrap justify-content-center">
                                    <a
                                      onClick={() => removeCartItem(index)}
                                      class=""
                                    >
                                      <MdDeleteOutline />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="box p-3">
                      <dl className="row">
                        {/* <dt className="col-sm-6">Sub total :</dt>
                        <dd className="col-sm-6 text-right">GHS {subtotal}</dd> */}
                        {/* <dt className="col-sm-6">Product Discount:</dt>
                        <dd className="col-sm-6 text-right">- GHS 0.00</dd>
                        <dt className="col-sm-6">Extra Discount:</dt>
                        <dd className="col-sm-6 text-right">
                          <button
                            className="btn btn-sm"
                            type="button"
                            data-toggle="modal"
                            data-target="#add-discount"
                          >
                            <i className="tio-edit" />
                          </button>
                          - 0.00$
                        </dd> */}
                        {/* <dt className="col-sm-6">Tax :</dt>
                        <dd className="col-sm-6 text-right">GHS {totalTax}</dd> */}
                        <dt className="col-12">
                          <hr className="mt-0" />
                        </dt>
                        <dt className="col-sm-6">Total :</dt>
                        <dd className="col-sm-6 text-right h4 b">
                          GHS {totalCost}
                        </dd>
                        <dt className="col-12">
                          <hr className="mt-0" />
                        </dt>
                        {paymentStatus === "Part Payment" && (
                          <>
                            <dt className="col-sm-8">Amount Paid :</dt>

                            <dd className="col-sm-4 text-right">
                              <input
                                type="number"
                                class="form-control text-right"
                                value={amountPaid}
                                onChange={(e) => {
                                  setAmountPaid(e.target.value);
                                  setBalance(
                                    parseFloat(
                                      totalCost - e.target.value
                                    ).toFixed(2)
                                  );
                                }}
                                name="amount_paid"
                                id=""
                              />
                            </dd>

                            <dt className="col-sm-8">Outstanding Balance:</dt>
                            <dd className="col-sm-4 text-right h4 b">
                              GHS {amountPaid === "0.00" ? totalCost : balance}
                            </dd>
                          </>
                        )}
                      </dl>
                      <div>
                        <form onSubmit={(e) => placeOrder(e)} id="order_place">
                          <div className="pos--payment-options mt-3 mb-3">
                            <h5 className="mb-3">Payment Status</h5>
                            <ul>
                              <li style={{ display: "block" }}>
                                <label>
                                  <input
                                    type="radio"
                                    name="payment_method"
                                    defaultValue="Full Payment"
                                    hidden
                                    defaultChecked
                                    onChange={(e) =>
                                      setPaymentStatus(e.target.value)
                                    }
                                  />
                                  <span>Full Payment</span>
                                </label>
                              </li>
                              <li style={{ display: "block" }}>
                                <label>
                                  <input
                                    type="radio"
                                    name="payment_method"
                                    value="Part Payment"
                                    hidden
                                    onChange={(e) =>
                                      setPaymentStatus(e.target.value)
                                    }
                                  />
                                  <span>Part Payment</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div className="row button--bottom-fixed g-1 bg-white">
                            {!placingOrder ? (
                              <>
                                <div className="col-sm-6">
                                  <a
                                    href="#"
                                    className="btn btn-outline-danger btn--danger btn-sm btn-block"
                                    onClick={() => emptyCart()}
                                  >
                                    <i className="fa fa-times-circle" /> Cancel
                                    Order
                                  </a>
                                </div>
                                <div className="col-sm-6">
                                  <button
                                    type="submit"
                                    className="btn btn--primary btn-sm btn-block"
                                  >
                                    <i className="fa fa-shopping-bag" />
                                    Place Order
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="d-flex align-items-center justify-content-center col-sm-12">
                                <RotatingLines
                                  visible={true}
                                  height="96"
                                  width="96"
                                  color="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PRODUCT DETAILS MODAL */}
      {addMedicineModal && (
        <SaleDrugDetailsModal
          drug={selectedMedicine}
          cart={cart}
          setCart={setCart}
          setAddMedicineModal={setAddMedicineModal}
          setSubtotal={setSubtotal}
          subtotal={subtotal}
          totalTax={totalTax}
          setTotalTax={setTotalTax}
          totalCost={totalCost}
          setTotalCost={setTotalCost}
        />
      )}

      {/* NEW CUSTOMER MODAL */}
      {addCustomerModal && (
        <AddNewCustomerModal
          setAddCustomerModal={setAddCustomerModal}
          getCustomersList={getCustomersList}
        />
      )}
    </main>
  );
};

export default NewSale;
