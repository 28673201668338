import React, { useEffect, useState } from 'react'
import { useAuthenticate } from '../../../context/AuthContext';
import axios from 'axios';
import { api_url } from '../../../utils/data';
import Loading from '../../../components/Loading/Loading';
import ReactPaginate from 'react-paginate';

const SubscriptionReport = () => {
  const {authToken,packages} = useAuthenticate()
  const [gettingReport, setGettingReport] = useState(true);
  const [reportData, setReportData] = useState([])
  const [subscriptionList, setSubscriptionList] = useState([])
  

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % subscriptionList.length;
    setItemOffset(newOffset);
  };
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = subscriptionList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(subscriptionList.length / itemsPerPage);



  const getSubscriptionReport = async () => {
    const config = {
      headers:{
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      }
    }

    await axios.get(`${api_url}/subscription/report/`, config).then((response)=>{
      setReportData(response.data);
      setSubscriptionList(response.data?.subscription_list);
      setGettingReport(false);
    }).catch((error)=>{
      console.log(error)
      setGettingReport(false);
    })
  }

  useEffect(()=>{
    getSubscriptionReport()
  },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
    {
      gettingReport ? (
        <Loading />
      ):(
        <div className="content container-fluid">
          <div className="page-header">
            <div className="media align-items-center">
              <div className="media-body pl-3">
                <h1 className="page-header-title mb-1">Subscription Report</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="card">
              <div className="card-header border-0">
                <div className="w-100 p-3">
                  <form className="w-100">
                    <div className="row ">
                      <div className="col-sm-6 col-md-4 col-lg-2">
                        <select
                          className="custom-select custom-select-sm text-capitalize min-h-45px"
                          name="branch_id"
                        >
                          <option disabled="" selected="">
                            --- Select Package ---
                          </option>
                          <option value="all" selected="">
                            All Packages
                          </option>
                          {
                            packages?.map((packageItem)=>(
                              <option value={packageItem?.id}>{packageItem?.name}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <div className="input-date-group">
                          <label className="input-label" htmlFor="start_date">
                            Start Date
                          </label>
                          <label className="input-date">
                            <input
                              type="date"
                              id="start_date"
                              name="start_date"
                              className="form-control flatpickr-custom min-h-45px"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <div className="input-date-group">
                          <label className="input-label" htmlFor="end_date">
                            End Date
                          </label>
                          <label className="input-date">
                            <input
                              type="date"
                              id="end_date"
                              name="end_date"
                              className=" form-control flatpickr-custom min-h-45px"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-12 col-lg-4 __btn-row">
                        
                        <button
                          type="submit"
                          id="show_filter_data"
                          className="btn btn--primary min-h-45px"
                        >
                          Show data
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="col-md-12 pt-4">
                    <div className="report--data">
                      <div className="row g-3">
                        <div className="col-sm-4">
                          <div className="order--card h-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="card-subtitle d-flex justify-content-between m-0 align-items-center">
                                <span>Total Subscription</span>
                              </h6>
                              <span
                                className="card-title text-success"
                                id="order_count"
                              >
                                {reportData?.total_subscriptions}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="order--card h-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="card-subtitle d-flex justify-content-between m-0 align-items-center">
                                <span>Paid Subscriptions</span>
                              </h6>
                              <span
                                className="card-title text-success"
                                id="item_count"
                              >
                                {reportData?.paid_subscriptions}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="order--card h-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="card-subtitle d-flex justify-content-between m-0 align-items-center">
                                <span>Free Trials</span>
                              </h6>
                              <span
                                className="card-title text-success"
                                id="order_amount"
                              >
                                {reportData?.free_subscriptions}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive" id="set-rows">
                <table className="table table-light table-align-middle">
                  <thead className="thead-light">
                    <tr>
                      <th>SL </th>
                      <th>Subscription ID</th>
                      <th>Shop</th>
                      <th>Package</th>
                      <th>Date Registered</th>
                      <th>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      currentItems?.map((subscription, index)=>(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {subscription?.subscription_id}
                          </td>
                          <td>
                            {subscription?.pharmacy}
                          </td>
                          <td>
                            {subscription?.package}
                          </td>
                          <td>
                            <div className="word-nobreak">{subscription?.registration_date}</div>
                          </td>
                          <td>
                          <div className="word-nobreak">{subscription?.expiry_date}</div>
                          </td>
                        </tr>
                      ))
                    }
                    
                  </tbody>
                </table>
                <div className="card-footer border-0">
              <div className="d-flex justify-content-center justify-content-sm-end">
                
                <nav>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="›"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="‹"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName="page-item active"
                    pageLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                  />
                  
                </nav>
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </main>
  )
}

export default SubscriptionReport