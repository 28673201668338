import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { api_url } from "../../utils/data";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";

const AddMultipleMedicine = () => {
    const {authToken} = useAuthenticate()
  const [file, setFile] = useState(null);
  const [addingMedicine, setAddingMedicine] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    setAddingMedicine(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = {
      drugs: file,
    };

    try {
      await axios
        .post(`${api_url}/inventory/medicines/multiple/`, formData, config)
        .then((response) => {
          if (response.status === 201) {
            Swal.fire({
              title: "Created!",
              text: "Products have been created.",
              icon: "success",
            }).then(() => {
              setAddingMedicine(false);
              window.location.reload();
            });
          }
        });
    } catch (error) {
      setAddingMedicine(false);
      if (error?.response?.status === 500) {
        return toast.error("Internal server error");
      }
      if (error?.response?.status === 400) {
        for (const property in error.response.data) {
          if (property !== "0") {
            toast.error(`${property} error: ${error.response.data[property]}`);
          } else {
            toast.error(`${error.response.data[property]}`);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div id="content" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="page-header-title">
            <span>Upload Bulk Products</span>
          </div>
        </div>
        <div className="px-5 py-2 border border-blue-600 rounded">
          <p>
            To upload multiple products, please follow the following instructions
          </p>
          <ul className="mb-5">
            <li>
              <a className="text-blue-400" href="https://apiv1.revicloud.com/media/bulk_product_upload.xlsx">Click here</a> to download the format of uploading the products in the
              excel format
            </li>
            <li>Provide the details of the products you want to upload</li>
            <li>
              Click on the "Upload File" button below to upload you filled excel
              sheet
            </li>
            <li>Click on the Add products button to submit file.</li>
          </ul>
            {addingMedicine ? (
                <div className="flex gap-3 items-center px-4 py-2 bg-blue-200 w-max rounded">
                    <RotatingLines
                      visible={true}
                      height="40"
                      width="40"
                      color="blue"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    /> Uploading Products 
                </div>
            ) : (
              <form onSubmit={(e)=>submitForm(e)} className="">
                <input
                  type="file"
                  name="uploadButton"
                  id="uploadButton"
                  className="hidden"
                  onChange={(e) => setFile(e.target.files[0])}
                  accept=".xlsx"
                />
                <label
                  htmlFor="uploadButton"
                  className="bg-blue-500 px-3 py-2 rounded text-white cursor-pointer"
                >
                  Upload File
                </label>
                <p>{file ? file.name : "No file selected"}</p>
                <button
                  type="submit"
                  className="bg-green-500 px-3 py-2 rounded text-white"
                >
                  Upload Products
                </button>
              </form>
            )}
        </div>
      </div>
    </div>
  );
};

export default AddMultipleMedicine;
