import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import LogoSmall from "../../assets/rxsmall.jpeg"
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";

const MedicineDetails = () => {
  const { categoryList, authToken,subscriptionDetails } = useAuthenticate();
  const [unitList, setUnitList] = useState([])
  const { medicine_id } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const [gettingProduct, setGettingProduct] = useState(true)
  const [updateData, setUpdateData] = useState({});

  const changeFormData = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const getProductDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios.get(`${api_url}/inventory/products/${medicine_id}/`,config).then((response)=>{
      setProductDetails(response.data);
      setGettingProduct(false);
    }).catch(error=>{
      console.error(error);
      if(error?.response?.status === 500){
        return toast.error("Internal server error");
      }
      if(error?.response?.status === 400){
        for(const property in error.response.data){
          if(property !== "0"){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }else{
        toast.error("Something went wrong");
      }
    })
  };

  const calculateFinalPrice = (manufacturer_price, markup_rate) => {
    if (manufacturer_price !== "") {
      
      let tax_amount = parseFloat(manufacturer_price) * (parseFloat(markup_rate) / 100);
      
      let final_price = parseFloat(parseFloat(manufacturer_price) + tax_amount).toFixed(2);
      setUpdateData({
        ...updateData,
        ["manufacturer_price"]: manufacturer_price,
        ["markup_rate"]: markup_rate,
        ["final_price"]: final_price,
      });
    }
  };

  const updateProduct = async (e) => {
    e.preventDefault();
    setUpdatingProduct(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = JSON.stringify(updateData)

    try {
      await axios
        .put(
          `${api_url}/inventory/products/${medicine_id}/`,
          formData,
          config
        )
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: "Updated!",
              text: "Product has been updated.",
              icon: "success",
            }).then(() => {
              setUpdatingProduct(false);
              window.location.href = "/products/list";
            });
          }
        });
    } catch (error) {
      
      console.log(error);
      setUpdatingProduct(false);
      if(error?.response?.status === 500){
        return toast.error("Internal server error");
      }
      if(error?.response?.status === 400){
        for(const property in error.response.data){
          if(property !== "0"){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }else{
        toast.error("Something went wrong");
      }
    }
  };

  const calculateClearanceDate = (days) => {
    
    if (days === "" || parseInt(days) <= 0){
      setUpdateData({
        ...updateData, ['clearance_date'] : null
      })
      return;
    }
    const resultDate = new Date() // today's date
    resultDate.setDate(resultDate.getDate() + parseInt(days)); // add days to today's date
    setUpdateData({
      ...updateData, ['clearance_date'] : resultDate.toISOString().slice(0, 10)
    })
    
  }

  const getUnitList = async () => {
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(
        `${api_url}/inventory/all-product-units/`,
        config
      )
      .then((response) => {
        setUnitList(response.data);
        
        
      })
      .catch((error) => {
        
        
      });
  };

  
  useEffect(() => {
    getUnitList();
    getProductDetails();
  }, []);
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            
            <span> Update Product </span>
          </h1>
        </div>
        {
          gettingProduct ? (
            <Loading />
          ):(
            <form className="row g-2" onSubmit={(e) => updateProduct(e)}>
              

              <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-dollar" />
                  </span>
                  <span> Product Details </span>
                  {
                    productDetails?.is_clearance_product && (
                      <span className='ml-4 bg-green-700 px-2 py-1 rounded text-[12px] text-white'>
                        Clearance Product
                      </span>
                    )
                  }
                </h5>
              </div>
              <div className="card-body">
                <div className="p-2">
                  <div className="row g-3">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Product Name
                          
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="en_name"
                          className="form-control"
                          placeholder="New Product"
                          required
                          onChange={(e) => changeFormData(e)}
                          value={
                            updateData?.name
                              ? updateData?.name
                              : productDetails?.name
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          SKU
                          
                        </label>
                        <div className="flex flex-col">

                          <input
                            type="text"
                            name="sku"
                            id="en_name"
                            className="form-control"
                            
                            onChange={(e) => changeFormData(e)}
                            value={
                              updateData?.sku
                                ? updateData?.sku
                                : productDetails?.sku
                            }
                          />
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Product Unit
                        </label>
                        <select
                        name="unit"
                        className="form-control js-select2-custom"
                        onChange={(e) => changeFormData(e)}
                      >
                        <option value="">---Select---</option>
                        {unitList.map((product_unit, index) => (
                          <option selected={productDetails?.unit === product_unit.id} value={product_unit.id} key={index}>
                            {product_unit.name} ({product_unit.short_name})
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Category
                        </label>
                        <select
                        name="category"
                        className="form-control js-select2-custom"
                        onChange={(e) => changeFormData(e)}
                      >
                        <option value="">---Select---</option>
                        {categoryList.map((category, index) => (
                          <option selected={productDetails?.category === category.id} value={category.id} key={index}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Days to clearance
                        </label>
                        <div className="flex flex-col">
                          <input
                            type="number"
                            min={0}
                            max={10000}
                            step="any"
                            name="days_to_clearance"
                            className="form-control"
                            value={
                              updateData?.days_to_clearance
                                ? updateData?.days_to_clearance
                                : productDetails?.days_to_clearance
                            }
                            onChange={(e)=>calculateClearanceDate(e.target.value)}
                            
                            
                          />
                          {
                            updateData?.clearance_date && (
                              <small>
                                This product will be moved to clearance on {updateData?.clearance_date}
                              </small>
                            )
                          }
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Clearance Discount (%)
                        </label>
                        <div className="flex flex-col">
                          <input
                            type="number"
                            min={0}
                            max={10000}
                            step={0.01}
                            
                            name="clearance_discount"
                            className="form-control"
                            onChange={(e) => changeFormData(e)}
                            value={
                              updateData?.clearance_discount
                                ? updateData?.clearance_discount
                                : productDetails?.clearance_discount
                            }
                            
                          />
                          {
                            updateData?.clearance_date && (
                              <small>
                                This product will be discounted in this percentage if it is moved to clearance
                              </small>
                            )
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="form-group mt-5 mb-0">
                  <label className="input-label" htmlFor="en_description">
                    Product Description
                  </label>
                  <textarea
                    name="description"
                    className="form-control h--172px"
                    id="en_hiddenArea"
                    onChange={(e) => changeFormData(e)}
                            value={
                              updateData?.description
                                ? updateData?.description
                                : productDetails?.description
                            }
                  />
                </div>
                </div>
              </div>
            </div>
          </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      <span className="card-header-icon">
                        <i className="tio-dollar" />
                      </span>
                      <span> Price information </span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="p-2">
                      <div className="row g-3">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlSelect1"
                            >
                              Product Type
                              <span className="input-label-secondary">*</span>
                            </label>
                            <select
                              name="medicine_type"
                              className="form-control js-select2-custom"
                              onChange={(e) => changeFormData(e)}
                            >
                              <option value="">---Select---</option>
                              <option value="Local" selected={productDetails?.medicine_type === "Local"}>Local</option>
                              <option value="Foreign" selected={productDetails?.medicine_type === "Foreign"}>Foreign</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Manufacturer's price
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000000}
                              step="any"
                              // onChange={(e) => changeFormData(e)}
                              onChange={(e) => {
                                // handleFormChange(e);
                                calculateFinalPrice(e.target.value, updateData?.markup_rate ? updateData?.markup_rate : productDetails?.markup_rate);
                              }}
                              value={
                                updateData?.manufacturer_price
                                  ? updateData?.manufacturer_price
                                  : productDetails?.manufacturer_price
                              }
                              name="manufacturer_price"
                              className="form-control"
                              placeholder="Ex : 349"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Product stock
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000000}
                              onChange={(e) => changeFormData(e)}
                              value={
                                updateData?.quantity
                                  ? updateData?.quantity
                                  : productDetails?.quantity
                              }
                              name="quantity"
                              className="form-control"
                              placeholder="Ex : 100"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Markup rate <span id="tax_symbol">(%)</span>
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000}
                              step="0.01"
                              // onChange={(e) => changeFormData(e)}
                              onChange={(e) => {
                                // handleFormChange(e);
                                calculateFinalPrice(
                                  updateData?.manufacturer_price ? updateData?.manufacturer_price : productDetails?.manufacturer_price,
                                  e.target.value
                                );
                              }}
                              value={
                                updateData?.markup_rate
                                  ? updateData?.markup_rate
                                  : productDetails?.markup_rate
                              }
                              name="markup_rate"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Final Unit price
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000000}
                              step="any"
                              onChange={(e) => changeFormData(e)}
                              value={
                                updateData?.final_price
                                  ? updateData?.final_price
                                  : productDetails?.final_price
                              }
                              name="final_price"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Expiry Date
                        </label>
                        <input
                          type="date"
                          
                          
                          name="expiry_date"
                          className="form-control"
                          
                          onChange={(e) => changeFormData(e)}
                          value={
                            updateData?.expiry_date
                              ? updateData?.expiry_date
                              : productDetails?.expiry_date
                          }
                        />
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="btn--container justify-content-end">
                  {!updatingProduct ? (
                    <button type="submit" className="btn btn--primary">
                      Update
                    </button>
                  ) : (
                    <RotatingLines
                      visible={true}
                      height="96"
                      width="96"
                      color="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  )}
                </div>
              </div>
            </form>
          )
        }
      </div>
    </main>
  );
};

export default MedicineDetails;
