import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";

const PharmacyDetails = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [pharmacyDetails, setPharmacyDetails] = useState({});
  const [pharmacyDetailsUpdate, setPharmacyDetailsUpdate] = useState({});
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [gettingPharmacyDetails, setGettingPharmacyDetails] = useState(true);

  const handleFormChange = (e) => {
    setPharmacyDetailsUpdate({
      ...pharmacyDetailsUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const updateDetails = async (e) => {
    e.preventDefault();
    setUpdatingDetails(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = new FormData();
    Object.keys(pharmacyDetailsUpdate).forEach((key) => {
      formData.append(key, pharmacyDetailsUpdate[key]);
    });

    await axios
      .put(`${api_url}/accounts/pharmacy-details/`, formData, config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Details updated",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setUpdatingDetails(false);
        toast.error("An error occured. Please try again.");
      });
  };

  const getPharmacyDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/accounts/pharmacy-details/`, config)
      .then((response) => {
        setPharmacyDetails(response.data);
        setGettingPharmacyDetails(false);
      })
      .catch((error) => {
        console.log(error);
        setGettingPharmacyDetails(false);
        toast.error("An error occured. Please try again.");
      });
  };

  useEffect(() => {
    
    getPharmacyDetails();
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <Helmet>
        <title>Stewards Pharmacy | Pharmacy Details</title>
      </Helmet>
      {gettingPharmacyDetails ? (
        <Loading />
      ) : (
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title">Pharmacy Details</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="navbar-vertical navbar-expand-lg mb-3 mb-lg-5">
                <button
                  type="button"
                  className="navbar-toggler btn btn-block btn-white mb-3"
                  aria-label="Toggle navigation"
                  aria-expanded="false"
                  aria-controls="navbarVerticalNavMenu"
                  data-toggle="collapse"
                  data-target="#navbarVerticalNavMenu"
                >
                  <span className="d-flex justify-content-between align-items-center">
                    <span className="h5 mb-0">Nav menu</span>
                    <span className="navbar-toggle-default">
                      <i className="tio-menu-hamburger" />
                    </span>
                    <span className="navbar-toggle-toggled">
                      <i className="tio-clear" />
                    </span>
                  </span>
                </button>
                <div
                  id="navbarVerticalNavMenu"
                  className="collapse navbar-collapse"
                >
                  <ul
                    id="navbarSettings"
                    className="js-sticky-block js-scrollspy navbar-nav navbar-nav-lg nav-tabs card card-navbar-nav"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="javascript:"
                        id="generalSection"
                      >
                        <FaUser className="nav-icon" />
                        Pharmacy Information
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <form onSubmit={(e) => updateDetails(e)} id="admin-settings-form">
                <div className="card mb-3 mb-lg-5" id="generalDiv">
                  <div className="profile-cover">
                    <div className="profile-cover-img-wrapper" />
                  </div>
                  <label
                    className="avatar avatar-xxxl avatar-uploader justify-content-center profile-cover-avatar"
                    htmlFor="avatarUploader"
                  >
                    {pharmacyDetailsUpdate?.pharmacy_logo ? (
                      <img
                        id="viewer"
                        className="avatar-img"
                        src={URL.createObjectURL(
                          pharmacyDetailsUpdate?.pharmacy_logo
                        )}
                        alt="user"
                      />
                    ) : (
                      <img
                        id="viewer"
                        className="avatar-img"
                        src={`https://apiv1.revicloud.com/${pharmacyDetails?.pharmacy_logo}`}
                        alt="shop"
                      />
                    )}
                    <input
                      type="file"
                      name="pharmacy_logo"
                      className="js-file-attach avatar-uploader-input"
                      id="customFileEg1"
                      accept=".jpg, .png, .jpeg"
                      onChange={(e) =>
                        setPharmacyDetailsUpdate({
                          ...pharmacyDetailsUpdate,
                          [e.target.name]: e.target.files[0],
                        })
                      }
                    />
                    <label
                      className="avatar-uploader-trigger"
                      htmlFor="customFileEg1"
                    >
                      <i className="tio-edit avatar-uploader-icon shadow-soft">
                        <MdEdit />
                      </i>
                    </label>
                  </label>
                </div>
                <div className="card mb-3 mb-lg-5">
                  <div className="card-header">
                    <h2 className="card-title h4">
                      <i className="tio-info" /> Basic information
                    </h2>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <label
                        htmlFor=""
                        className="col-sm-3 col-form-label input-label"
                      >
                        Shop Name
                        <i
                          className="tio-help-outlined text-body ml-1"
                          data-toggle="tooltip"
                          data-placement="top"
                        />
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm-down-break">
                          <input
                            type="text"
                            className="form-control"
                            name="pharmacy_name"
                            id="firstNameLabel"
                            placeholder="Shop Name"
                            aria-label="Shop Name"
                            value={
                              pharmacyDetailsUpdate?.pharmacy_name
                                ? pharmacyDetailsUpdate?.pharmacy_name
                                : pharmacyDetails?.pharmacy_name
                            }
                            onChange={(e) => handleFormChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <label
                        htmlFor=""
                        className="col-sm-3 col-form-label input-label"
                      >
                        Shop Location
                        <i
                          className="tio-help-outlined text-body ml-1"
                          data-toggle="tooltip"
                          data-placement="top"
                        />
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm-down-break">
                          <input
                            type="text"
                            className="form-control"
                            name="pharmacy_location"
                            id=""
                            placeholder="Location"
                            aria-label="Location"
                            value={
                              pharmacyDetailsUpdate?.pharmacy_location
                                ? pharmacyDetailsUpdate?.pharmacy_location
                                : pharmacyDetails?.pharmacy_location
                            }
                            onChange={(e) => handleFormChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <label
                        htmlFor="phoneLabel"
                        className="col-sm-3 col-form-label input-label"
                      >
                        Phone
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="js-masked-input form-control"
                          name="pharmacy_phone"
                          id="phoneLabel"
                          value={
                            pharmacyDetailsUpdate?.pharmacy_phone
                              ? pharmacyDetailsUpdate?.pharmacy_phone
                              : pharmacyDetails?.pharmacy_phone
                          }
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label
                        htmlFor="newEmailLabel"
                        className="col-sm-3 col-form-label input-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          className="form-control"
                          name="pharmacy_email"
                          id="newEmailLabel"
                          placeholder="Enter new email address"
                          aria-label="Enter new email address"
                          value={
                            pharmacyDetailsUpdate?.pharmacy_email
                              ? pharmacyDetailsUpdate?.pharmacy_email
                              : pharmacyDetails?.pharmacy_email
                          }
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label
                        htmlFor="newEmailLabel"
                        className="col-sm-3 col-form-label input-label"
                      >
                        About Shop
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          name="about_pharmacy"
                          className="form-control"
                          id=""
                          cols="30"
                          rows="10"
                          value={
                            pharmacyDetailsUpdate?.about_pharmacy
                              ? pharmacyDetailsUpdate?.about_pharmacy
                              : pharmacyDetails?.about_pharmacy
                          }
                          onChange={(e) => handleFormChange(e)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      {updatingDetails ? (
                        <RotatingLines
                          visible={true}
                          height="96"
                          width="96"
                          color="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          ariaLabel="rotating-lines-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>

              <div id="stickyBlockEndPoint" />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default PharmacyDetails;
